<template>
  <el-dialog top="1vh" title="货车详情" width="1050px" :visible.sync="is_show_in_page" :close-on-click-modal="false">
    <el-form class="form_ordinary" label-width="150px">
      <div class="big_tit" style="margin-top: 0px">基本数据</div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="所有人" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_owner_name"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="车辆识别代号" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_vin_code"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="使用性质" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_used"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="行驶证发证机关" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.dl_ca"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="行驶证注册日期" style="width: 45%">
          <el-date-picker style="width: 274px" v-model="time.dl_reg_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
        </el-form-item>
        <el-form-item class="el_form_item" label="行驶证发证日期" style="width: 45%">
           <el-date-picker style="width: 274px" v-model="time.dl_award_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="道路运输证号" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.tl_code"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="道路运输经营许可号" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.tl_cert_code"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="车牌号" style="width: 45%">
          <el-input
            class="el_inner_width"
            v-model="form.truck_plate_num"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="车牌类型" style="width: 45%" >
          <el-select class="el_inner_width" v-model="form.truck_plate_type">
            <el-option label="未选择" value="0"></el-option>
            <el-option label="黄牌" value="1"></el-option>
            <el-option label="蓝牌" value="2"></el-option>
            <el-option label="绿牌" value="3"></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="额定载重(吨)" style="width: 45%" >
          <el-input class="el_inner_width" v-model="form.truck_weight" clearable></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="额载体积(方)" style="width: 45%">
          <el-input class="el_inner_width" v-model="form.truck_volume" clearable></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="能源类型" style="width: 45%" >
          <el-select class="el_inner_width" v-model="form.en_type">
            <el-option label="汽油" value="A"></el-option>
            <el-option label="柴油" value="B"></el-option>
            <el-option label="电" value="C"></el-option>
            <el-option label="混合油" value="D"></el-option>
            <el-option label="天然气" value="E"></el-option>
            <el-option label="液化石油气" value="F"></el-option>
            <el-option label="甲醇" value="L"></el-option>
            <el-option label="乙醇" value="M"></el-option>
            <el-option label="太阳能" value="N"></el-option>
            <el-option label="混合动力" value="O"></el-option>
            <el-option label="无" value="Y"></el-option>
            <el-option label="其他" value="Z"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form_item" label="货车类型" style="width: 45%">
          <el-cascader class="el_inner_width" v-model="form.truck_type" :options="truck_type" placeholder="必填" filterable ></el-cascader>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="总质量" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_weight_max"></el-input>
        </el-form-item>
      </div>
      <div class="big_tit">证件相关
         <el-button  size="mini" type="text" @click="ocr_img()" style="margin-left: 20px">识别行驶证</el-button>
      </div>
      <div class="tab1_inner">
        <div class="el_form_item" label="" style="width: 48%">
          <div class="img_btn">
            <div v-if="!dl_img_src" class="btn">未上传</div>
            <el-image v-else class="img" :src="dl_img_src" :previewSrcList="dlList">
            </el-image>
          </div>
          <div class="bottom_text">
            行驶证
            <el-button class="btn_left" size="mini" type="text"  @click="voucher_add(1)">重新上传</el-button >
          </div>
        </div>
        <div class="el_form_item" label="" style="width: 48%;margin-left: 1%" >
          <div class="img_btn">
            <div v-if="!dl_img_src2" class="btn">未上传</div>
            <el-image v-else class="img" :src="dl_img_src2" :previewSrcList="dlList2">
            </el-image>
          </div>
          <div class="bottom_text">
            行驶证副本
            <el-button class="btn_left" size="mini" type="text"  @click="voucher_add(4)">重新上传</el-button >
          </div>
        </div>
        <div class="el_form_item" style="width: 48%">
          <div class="img_btn">
            <div v-if="!tl_img_src" class="btn">未上传</div>
            <el-image v-else class="img" :src="tl_img_src" :previewSrcList="tlList">
            </el-image>
          </div>
          <div class="bottom_text">
            道路运输证
            <el-button class="btn_left" size="mini" type="text" @click="voucher_add(2)" >重新上传</el-button>
          </div>
        </div>
      </div>

      <div class="big_tit">车辆来源</div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="来源说明" style="width: 49%">
          <el-input class="el_inner_width" v-model="form.truck_source" disabled ></el-input>
        </el-form-item>
        <div class="el_form_item" label="" style="width: 48%">
          <div class="img_btn">
            <div v-if="!truck_source_proof_src" class="btn">未上传</div>
            <el-image v-else class="img" :src="truck_source_proof_src" :previewSrcList="truck_source_List">
            </el-image>
          </div>
          <div class="bottom_text">
            车辆来源凭证
            <el-button class="btn_left" size="mini" type="text" @click="voucher_add(3)">重新上传</el-button >
          </div>
        </div>
      </div>

      <div class="big_tit">审核相关</div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="失败原因" style="width: 100%">
          <el-autocomplete
            class="el_inner_width"
            v-model="form.check_faild_reason"
            clearable
            :fetch-suggestions="querySearch"
          ></el-autocomplete>
        </el-form-item>
      </div>
    </el-form>
    <div class="bottom_btns">
      <el-button type="success" @click="pass()">通过</el-button>
      <el-button type="danger" @click="unpass()">驳回</el-button>
    </div>

    <el-dialog
      top="2vh"
      width="90%"
      title="查看大图"
      :modal="false"
      :visible.sync="img.is_show"
    >
      <img :src="img.src" style="width: 100%" />
    </el-dialog>
    <!-- 凭证添加界面 -->
    <el-dialog
      title="证件上传" top="2vh" width="500px" :visible.sync="upl.is_show" :modal="false" >
      <div class="upl">
        <div class="img_area" @click="voucher_add_sub">
          <img v-if="upl.src" :src="upl.src" class="show" />
          <i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
        </div>

        <div class="btn_area">
          <el-button type="primary" @click="voucher_sub(num)">上传</el-button>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import truck_type_handle from "@/my/_truck_type_handle.js";
import { Loading } from "element-ui";
export default {
  props: {
    is_show: Number, //是否显示
    truck_info: "", //货车详情
  },
  data() {
    return {
      //页面是否显示
      is_show_in_page: false,

      //车辆id
      truck_id: "",

      //表单
      form: {
        truck_plate_num: "", //车牌号
        truck_plate_type: "", //车牌照类型(0:未知,1:黄牌,2:蓝牌,3:绿牌)
        truck_weight: "", //货车额定载重(单位:吨)
        truck_volume: "", //货车额载体积(单位:方)
        en_type: "", //能源类型(A:汽油,B:柴油,C:电,D:混合油,E:天然气,F:液化石油气,L:甲醇,M:乙醇,N:太阳能,O:混合动力,Y:无,Z:其他)
        truck_type: null, //货车类型
        truck_source: "", //车辆来源
        check_faild_reason: "", //审核失败原因

        truck_vin_code:'', // 车辆识别代号 
        truck_used:'', // 使用性质 
        dl_ca:'', //行驶证发证机关 
        dl_reg_date:'', //行驶证注册日期 
        dl_award_date:'',// 行驶证发证日期 
        tl_code:'', //道路运输证号
        tl_cert_code:'', //道路运输证经营许可证号
        truck_weight_max:'', //车辆总重(单位:吨)
        truck_owner_name:'', //所有人姓名 
      },
      //上传界面
      upl: {
        //是否显示上传界面
        is_show: false,

        //地址
        src: "",

        //备注
        mark: "",
      },
      num: "",
      //行驶证
      dl_img_src: "",
      dlList: [],
      dl_img_src2:'', //行驶证副本
      dlList2: [], 
      //道路运输证
      tl_img_src: "",
      tlList: [],
      //车辆来源证明
      truck_source_proof_src: "",
      truck_source_List: [],

      //大图
      img: {
        is_show: false,
        src: "",
      },
      //日期
      time:{
        dl_reg_date:'',
        dl_award_date:'',
      },
      //车型
      truck_type: [],
      //驳回原因数组
      restaurants: [
        {"value": "车辆非营运"},
        {"value": "道路运输证与行驶证不一致"},
        {"value": "缺少道路运输证"},
        {"value": "缺少传行驶证"},
      ],
    };
  },
  watch: {
    is_show(new_data) {
      if (new_data) {
        this.is_show_in_page = true;
        this.img.is_show = false;
      }
    },
    truck_info(new_data) {
      if (new_data) {
        this.load_data(new_data);
      }
    },
  },
  created() {
    //获取参数
    this.get_creat_para();
  },
  methods: {
    // 行驶证识别
    ocr_img(){
      //开启loding
     Loading.service(this.options);
        this.$my.net.req({
          take_over_control:1,
						data:{
						mod:'res',
						ctr:'ocr_info',
            type:2,
            url:this.dl_img_src
					},
					callback:(data)=>{
            if(data.code ==0){
                 //为空置入数据
                 if(!this.time.dl_reg_date && !data.msg.registerDate){
                  this.time.dl_reg_date = ""
                  }else if(!this.time.dl_reg_date){
                    this.time.dl_reg_date = Number(data.msg.registerDate) *1000
                  }
                  if(!this.time.dl_award_date && !data.msg.issueDate){
                    this.time.dl_award_date = ""
                  }else if(!this.time.dl_award_date){
                    this.time.dl_award_date = Number(data.msg.issueDate) *1000
                  }
                  if(!this.form.truck_owner_name){
                    this.form.truck_owner_name = data.msg.owner
                  }
                  if(!this.form.truck_plate_num){
                    this.form.truck_plate_num = data.msg.vehicleNumber
                  }
                  if(!this.form.truck_used){
                    this.form.truck_used = data.msg.useCharacter
                  }
                  if(!this.form.truck_vin_code){
                    this.form.truck_vin_code = data.msg.vin
                  }
                  if(!this.form.dl_ca){
                    this.form.dl_ca = data.msg.issuingOrganizations
                  }
                  this.$my.other.msg({
                    type:'success',
                    str:"识别成功"
                  });
                }else{
                  this.$my.other.msg({
                    type:'info',
                    str:data.error_info
                  });
                }
               // 加载完毕
							let loadingInstance = Loading.service(this.options);
							this.$nextTick(() => {
							loadingInstance.close();
						});
					 }
			});
    },
   // 驳回原因输入框事件
    querySearch(queryString, cb){
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
    },
    createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    voucher_add(num) {
      this.num = num;
      //打开添加凭证界面

      //打开上传界面
      this.upl.is_show = true;

      //清空上次上传的图片
      this.upl.src = "";

      //清空备注
      this.upl.mark = "";
    },
    //上传
    voucher_sub(num) {
      //上传
      this.$my.qiniu.upl({
        bucket: "driversourceproof",
        file_path: this.upl.src,
        callback: (data) => {
          var key = data.key.split('__v__1')
          //清空
          var dl_img = "";
          var dl_img2 = "";
          var tl_img = "";
          var truck_source_proof = "";
          // 判断
          if (num == 1) {
            dl_img = key[0];
          } else if (num == 2) {
            tl_img = key[0];
          } else if(num ==3){
            truck_source_proof = key[0];
          }else if(num ==4){
            dl_img2 = key[0]
          }
          // //上传
          this.$my.net.req({
            data: {
              mod: "truck",
              ctr: "truck_img_by_admin",
              truck_id: this.truck_info.id,
              dl_img,
              tl_img,
              dl_img2,
              truck_source_proof,
            },
            callback: (data) => {
              //关闭添加凭证界面
              this.upl.is_show = false;

              // //刷新数据
              this.init();
            },
          });
        },
      });
    },
    load_data(new_data) {
      this.dlList = []  // 清空数据
      this.dlList2 = []
      this.tlList = []
      // 置入数据
      this.truck_id = new_data.id;
      this.form.truck_plate_num = new_data.truck_plate_num;
      this.form.truck_plate_type = new_data.truck_plate_type;
      this.form.truck_weight = new_data.truck_weight;
      this.form.truck_volume = new_data.truck_volume;
      this.form.en_type = new_data.en_type;
      this.form.truck_vin_code =new_data.truck_vin_code, // 车辆识别代号
      this.form.truck_used =new_data.truck_used // 使用性质
      this.form.dl_ca =new_data.dl_ca //行驶证发证机关
      this.form.tl_code =new_data.tl_code //道路运输证号
      this.form.tl_cert_code =new_data.tl_cert_code //道路运输证经营许可证号
      this.form.truck_weight_max =new_data.truck_weight_max //车辆总重(单位:吨)
      this.form.truck_owner_name =new_data.truck_owner_name //所有人姓名
      this.form.truck_type = [
        new_data.truck_type_name,
        new_data.truck_type_name2,
      ];
      this.form.truck_source = new_data.truck_source;
      this.form.check_faild_reason = new_data.check_faild_reason;
        //日期处理
      if(new_data.dl_reg_date !=0){
          this.time.dl_reg_date = Number(new_data.dl_reg_date) * 1000
        }else{
          this.time.dl_reg_date = ''
        }
      if(new_data.dl_award_date !=0){
          this.time.dl_award_date = Number(new_data.dl_award_date) * 1000
        }else{
          this.time.dl_award_date = ''
        }
      //图片转换
      if(new_data.dl_img){
        new_data.dl_img.forEach(item=>{
          this.dlList.push(this.$my.qiniu.make_src("truckdl",item));
        })
      }
      this.dl_img_src = this.$my.qiniu.make_src("truckdl", this.dlList[0]);
     if(new_data.dl_img2){
        new_data.dl_img2.forEach(item=>{
          this.dlList2.push(this.$my.qiniu.make_src("truckdl",item));
        })
      }
      this.dl_img_src2 = this.$my.qiniu.make_src("truckdl", this.dlList2[0]);
      if(new_data.tl_img){
        new_data.tl_img.forEach(item=>{
          this.tlList.push(this.$my.qiniu.make_src("trucktl", item));
        })
      }
      this.tl_img_src = this.$my.qiniu.make_src("trucktl", this.tlList[0]);
      this.truck_source_proof_src = this.$my.qiniu.make_src(
        "trucksourceproof",
        new_data.truck_source_proof
      );
      this.truck_source_List[0] = this.$my.qiniu.make_src(
        "trucksourceproof",
        new_data.truck_source_proof
      );
    },
    // 初始化
    init() {
      this.$my.net.req({
        data: {
          mod: "truck",
          ctr: "truck_list_by_admin",
          truck_plate_num: this.truck_info.truck_plate_num,
        },
        callback: (data) => {
          data.list.forEach((item) => {
            if (item.creat_time == this.truck_info.creat_time) {
              this.load_data(item);
            }
          });
        },
      });
    },
    voucher_add_sub() {
      //提交凭证

      //选择图片
      this.$my.qiniu.choose_img({
        success: (res) => {
          //预览
          this.upl.src = res.src;
        },
      });
    },

    time_handle(){
       this.form.dl_reg_date = parseInt(this.time.dl_reg_date /1000);
       this.form.dl_award_date = parseInt(this.time.dl_award_date / 1000);
    },
    //不通过
    unpass() {
      //失败原因
      if (!this.form.check_faild_reason.trim()) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少失败原因",
        });
        return;
      }

      //询问
      this.$my.other.confirm({
        content: "点击'确定'驳回审核",
        confirm: () => {
          //调接口
          this.$my.net.req({
            data: {
              mod: "truck",
              ctr: "truck_edit_by_admin",
              truck_id: this.truck_id,
              status: 3,
              check_faild_reason: this.form.check_faild_reason.trim(),
            },
            callback: (data) => {
              this.$my.other.msg({
                type: "success",
                str: "操作成功",
              });
              this.is_show_in_page = false;
              this.$emit("close");
            },
          });
        },
      });
    },

    //通过
    pass() {
      //取出数据
      let new_data = this.form;

      //车牌类型
      if (["1", "2", "3"].indexOf(new_data.truck_plate_type) == -1) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少车牌类型",
        });
        return;
      }

      //额定载重
      if (!this.$my.check.is_num(new_data.truck_weight)) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少额定载重",
        });
        return;
      }
      if (new_data.truck_weight <= 0) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少额定载重",
        });
        return;
      }

      //额定体积
      if (!this.$my.check.is_num(new_data.truck_volume)) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少额定载重",
        });
        return;
      }
      if (new_data.truck_volume <= 0) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少额定载重",
        });
        return;
      }

      //货车类型
      if (!this.$my.check.is_arr(new_data.truck_type)) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少货车类型",
        });
        return;
      }
      if (new_data.truck_type.length != 2) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少货车类型",
        });
        return;
      }
      //时间转换
      this.time_handle()
      //询问
      this.$my.other.confirm({
        content: "点击'确定'通过审核",
        confirm: () => {
          //调接口
          this.$my.net.req({
            data: {
              mod: "truck",
              ctr: "truck_edit_by_admin",
              truck_id: this.truck_id,
              ...this.form,
              status: 2,
              truck_plate_type: new_data.truck_plate_type,
              truck_weight: new_data.truck_weight,
              truck_volume: new_data.truck_volume,
              en_type: new_data.en_type,
              truck_type_name: new_data.truck_type[0],
              truck_type_name2: new_data.truck_type[1],
              examine:1,
            },
            callback: (data) => {
              this.$my.other.msg({
                type: "success",
                str: "操作成功",
              });
              this.is_show_in_page = false;
              this.$emit("close");
            },
          });
        },
      });
    },

    //获取创建参数
    get_creat_para() {
      //尝试从缓存中取出
      let creat_para = this.$my.cache.get("cargo_creat_page_creat_para");

      //如果取出成功
      if (creat_para) {
        this.truck_type = creat_para.truck_type;
        return;
      }

      //如果取出失败,去服务器读取
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "cargo_creat_para",
        },
        callback: (data) => {
          //格式化车型数据
          let truck_type = truck_type_handle.for_el_cascader(data.truck_type);

          //缓存之
          this.$my.cache.set({
            cargo_creat_page_creat_para: {
              truck_type: truck_type,
              cargo_type: data.cargo_type,
            },
          });

          //置入
          this.truck_type = truck_type;
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el_form_item {
  margin: 0;
  .img_btn {
    border: 1px solid #bbb;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    height: 300px;
    .btn {
      text-align: center;
      line-height: 290px;
      font-size: 14px;
    }

    .img {
      position: relative;
      display: block;
      height: 290px;
      /deep/.el-image__inner {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto !important;
      }
    }
  }
  .bottom_text {
    margin-top: 5px;
    text-align: center;
  }
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.bottom_btns {
  text-align: center;
  margin-top: 30px;
}

.form_ordinary {
  // height: calc(100% - 91px);
  padding: 10px 0;
  overflow-y: auto;
}
.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}

.voucher {
  margin-top: -10px;
  .header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .list_none {
    height: 300px;
    line-height: 300px;
    text-align: center;
    font-size: 18px;
  }
}
</style>